<script setup>
import { onMounted, ref } from "vue";
import { useRoute } from "vue-router/auto";
import ClientLayout from "@/layouts/ClientLayout.vue";
import useClients from "@/hooks/useClients";
import { useToast } from "vue-toastification";

const route = useRoute();
const toast = useToast();

const newInvite = ref("");

const { client, fetchClient, users, fetchClientUsers, toggleUserStatus, deleteClientUser, fetchClientInvites, invites, deleteClientInvite, sendClientInvite } = useClients();

onMounted(async () => {
    await fetchClient(route.params.id);
    await fetchClientUsers(route.params.id);
    await fetchClientInvites(route.params.id);
});

const handleUserStatus = async (id, status) => {
    const res = await toggleUserStatus(id, { active : status});
    if (res) {
        await fetchClientUsers(route.params.id);
        const msg = status ? "User Enabled." : "User Disabled.";
        toast.success(msg);
    }
};

const deleteUser = async(id) => {
    const res = await deleteClientUser(id);
    if (res) {
        await fetchClientUsers(route.params.id);
    }
};

const deleteInvite = async (id) => {
    const res = await deleteClientInvite(route.params.id, id);
    if (res) {
        fetchClientInvites(route.params.id);
    }
};

const sendInvite = async () => {
    if (!newInvite.value) {
        toast.error("Email is required.");
        return;
    }

    const res = await sendClientInvite(route.params.id, { email: newInvite.value });
    if (res) {
        await fetchClientInvites(route.params.id);
        newInvite.value = "";
    }
};

</script>

<template>
    <ClientLayout :client="client">
        <div class="w-100 px-4" v-if="client">
            <h1 class="mb-4 text-center">User Management</h1>
            <v-card class="pa-4">
                <v-card-title class="text-center title-text-size mb-4">Pending Invites</v-card-title>
                <div class="px-4">
                    <v-row>
                        <v-col cols="4" class="header-col">Email</v-col>
                        <v-col cols="4" class="header-col">Sent</v-col>
                        <v-col cols="4" class="header-col"></v-col>
                    </v-row>

                    <v-row v-for="(invite, index) in invites" :key="index" class="table-row" align="center">
                        <v-col cols="4">{{ invite.email }}</v-col>
                        <v-col cols="4">{{ invite.created_at }}</v-col>
                        <v-col cols="4">
                            <v-btn text color="red" variant="text" @click="deleteInvite(invite.id)">Delete</v-btn>
                        </v-col>
                    </v-row>

                    <v-row class="mt-10 table-footer">
                        <v-col cols="8">
                            <v-text-field v-model="newInvite" label="Enter an email to invite" variant="outlined"
                                density="compact"></v-text-field>
                        </v-col>
                        <v-col cols="4">
                            <v-btn color="grey" size="small" @click="sendInvite">Send Invite</v-btn>
                        </v-col>
                    </v-row>
                </div>

            </v-card>
        </div>

        <div class="w-100 px-4 pt-6" v-if="client">
            <v-card class="pa-4">
                <v-card-title class="text-center title-text-size mb-4">User Accounts</v-card-title>

                <v-row class="table-header">
                    <v-col cols="1">Status</v-col>
                    <v-col cols="4">Email</v-col>
                    <v-col cols="2">Name</v-col>
                    <v-col cols="2">Last Login</v-col>
                    <v-col cols="3"></v-col>
                </v-row>

                <v-row v-if="users?.length > 0" v-for="(user, index) in users" :key="index" :class="users.length - 1 == index ? '' : 'table-row'" align="center">
                    <v-col cols="1">
                        <div class="badge badge-pill" :class="user.active ? 'badge-success' : 'badge-danger'">{{ user.active ? "Active" : "Disabled" }}</div>
                    </v-col>
                    <v-col cols="4">{{ user.email }}</v-col>
                    <v-col cols="2">{{ user.name }}</v-col>
                    <v-col cols="2">{{ user?.last_login_at }}</v-col>
                    <v-col cols="3">
                        <v-btn variant="text" :color="user.active? 'red' : 'green'" @click="handleUserStatus(user.id, !user.active)">{{ user.active ? "Disable" : "Enable" }}</v-btn>
                        <v-btn variant="text" color="red" @click="deleteUser(user.id)">Delete</v-btn>
                    </v-col>
                </v-row>
                <v-row v-else >
                    <v-col class="text-center">
                        <span>No users yet. Invite some above.</span>
                    </v-col>
                </v-row>
            </v-card>
        </div>
    </ClientLayout>
</template>

<style scoped>

.badge {
    display: inline-block;
    padding: 0.25em 0.4em;
    font-size: 75%;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.badge-pill {
    padding-right: 0.6em;
    padding-left: 0.6em;
    border-radius: 10rem;
}

.badge-success {
    background-color: green;
    color: white;
}

.badge-danger {
    background-color: red;
    color: white;
}


.header-col {
    border-bottom: 1px solid #ccc;
    font-weight: bold;
}

.table-row {
    border-bottom: 1px solid #eee;
}

.table-footer {
    margin-top: 20px;
}

.table-header {
    border-bottom: 1px solid #ccc;
    font-weight: bold;
}

.table-row {
    border-bottom: 1px solid #eee;
}

.status-avatar {
    display: inline-block;
}

.status-dot {
    background-color: green;
    border-radius: 10%;
    padding: 3px;
    font-size: 13px;
    text-align: center;
    width: 50px;
    height: 25px;
    color: white;
}
</style>